@import "../../../styles/mixins";

.splashBackgroundImg {
  @include flex(column, stretch, center);
  position: relative;

  .videoWrap {
    position: absolute;
    z-index: -99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.splashBackgroundImg,
.offerBackground {
  @include splash;
  min-height: 100svh;
  height: 100vh;

  @include sm-max {
    min-height: 100svh;
    height: auto;
    padding: 70px 0;
  }

  &::before {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.offerBackground {
  height: auto;
  padding-top: 70px;

  &::before {
    background-color: rgba(0, 0, 0, 0.9);
  }

  @include sm-max {
    height: auto;
  }
}

.callToActionBackground {
  background-color: var(--color-green-dark);
  padding: 100px 0;
  min-height: 30svh;

  @include lg-max {
    display: flex;
    align-items: center;
  }

  @include md-max {
    padding: 20px 0;
    min-height: 40vh;
  }

  @include sm-max {
    min-height: 50vh;
    height: 50vh;
  }
}
