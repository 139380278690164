@import "../../../../styles/mixins";
@import "../../../../styles/typography";

.headline {
  @extend .h2;

  color: var(--color-light);
  margin: 27px 0 60px 0;

  @include sm-max {
    margin: 18px 0 18px 0;
  }

  &::after {
    content: "";
    display: block;
    height: 3px;
    width: 33.33%;
    background-color: var(--color-main);
    margin-top: 30px;

    @include sm-max {
      margin-top: 15px;
    }
  }
}
