@import "../../../styles/mixins";
@import "../../../styles/variable";

.sideOpenMenu {
  padding: 0 10px;

  color: var(--color-light);
  @include flex(column, stretch, space-between);
  overflow-wrap: scroll;

  .abstractWrap {
    @include flex(column, center, space-between);
    text-transform: uppercase;

    .imgAbstract {
      gap: 10px;
      @include flex(row, flex-start, space-between);

      @include sm-max {
        flex-direction: column;
        align-items: center;
      }

      img {
        width: 100px;
        height: 100px;
        margin-right: 20px;
      }
    }
  }

  .headerName {
    font-size: var(--font-d-btn);
    padding: 16px 0;
    border-bottom: 1px solid var(--color-main);
    text-align: justify;
    margin-bottom: 16px;
  }

  .btnWrap {
    @include flex(column, stretch, flex-start);
    top: 10px;
  }

  .megaWrap {
    @include flex(row, stretch, space-between);
    gap: 15px;
    flex-wrap: wrap;
    text-align: left;
    margin-bottom: 20px;

    @include md-max {
      flex-direction: column;
      justify-content: center;
    }

    .megaCartWrap {
      flex-grow: 1;

      .mainTitle {
        font-weight: bold;
      }

      ul {
        display: flex;
        flex-direction: column;

        .listItems {
          font-weight: lighter;
          text-transform: none;
        }
      }
    }
  }
}
