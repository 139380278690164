@import "../../../styles/mixins";
@import "../../../styles/variable";

.greenbackground {
  background-color: var(--color-green-dark);
  padding: 40px 0;

  @include md-max {
    padding: 10px 0;
  }

  @include sm-max {
    padding: 80px 0;
  }
}

.knowledge,
.integration,
.design,
.execution {
  padding: 120px 0 90px 0;
  text-align: left;
}

.integration {
  @include flex(column, center, center);
  min-height: 80vh;
}

.design {
  @include flex(row, center, center);
  background-color: var(--color-grey);
  min-height: 80vh;

  .designCarts {
    @include flex(row, flex-start, space-between);
    flex-wrap: wrap;
    padding: 50px 0 0 0;
    flex-grow: 1;
    gap: 50px;

    .cart {
      background-color: var(--color-light);
      @include flex(column, space-between, center);
      flex-grow: 1;

      @include md-max {
        width: 100%;
        // min-height: 100vh;
      }

      .header {
        z-index: 9;
        text-transform: uppercase;
        font-family: var(--header-font);
        letter-spacing: 1px;
        background-color: var(--color-dark);
        color: var(--color-light);
        padding: 10px 20px;

        @include md-max {
          height: auto;
        }
      }

      .list {
        padding: 20px 20px;
        border-radius: 2px;
        box-shadow: var(--shadow);
        border: 1px solid #ccc;
        gap: 20px;
        @include flex(column, flex-start, flex-start);
        transition: var(--transition);
        height: 270px;

        @include md-max {
          width: 100%;
        }

        @include sm-max {
          height: auto;
          padding: 20px 20px;
        }

        li {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
  }
}

.callToActionBackground {
  background-color: var(--color-green-dark);
  padding: 100px 0;
  min-height: 30svh;

  @include lg-max {
    display: flex;
    align-items: center;
  }

  @include md-max {
    padding: 20px 0;
    min-height: 40vh;
  }

  @include sm-max {
    min-height: 50vh;
    height: 50vh;
  }
}

.execution {
  background-color: var(--color-grey);
  min-height: 100vh;
  @include flex(column, center, center);
}

.knowledge {
  min-height: 100vh;
  @include flex(column, center, center);
}
