@import "../../../styles/mixins";

.cookieInfo {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  bottom: 0;
  z-index: 888;

  @include sm-max {
    overflow-y: scroll;
  }

  .modal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-dark);
    opacity: 0.4;
  }

  .info {
    z-index: 998;
    position: absolute;
    bottom: 0%;
    width: 100%;
    min-height: 35%;
    @include flex(column, flex-start, space-between);
    background-color: var(--color-light);
    opacity: 1;
    padding: 40px 30px;
    text-align: left;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);

    @include sm-max {
      padding: 20px 10px;
    }

    .text {
      padding: 20px 0;
      border-top: 2px solid var(--color-main);
      margin: 20px 0 0 0;
    }
  }
}

.buttonsWrap {
  @include flex(column, flex-start, flex-end);
  flex-wrap: wrap;

  .greenTransparentBckg {
    @include btn;
    background-color: var(--color-dark);
    border: none;
    color: var(--color-light);
    font-size: 14px;
    line-height: 22px;
    font-weight: var(--fw-900);
    width: 100%;

    &:hover {
      background-color: var(--color-green-dark);
    }
  }

  .goToCookiePolicy {
    color: var(--color-grey-dark);
    padding-top: 20px;

    span {
      color: var(--color-green-dark);
    }
  }
}
