@import "../../../../styles/mixins";

.formBox {
  @include flex(column, flex-start, space-between);
  padding: 20px 0 20px 40px;
  border-radius: 8px;
  min-width: 60%;
  width: 60%;

  @include lg-max {
    width: 100%;
    padding: 20px 0;
  }

  @include sm-max {
    align-items: center;
  }

  .contactForm {
    width: 100%;
    overflow: hidden;

    .checkboxProcessing {
      font-size: 14px;
      @include flex(row, flex-start, flex-start);
      text-align: justify;
      padding: 0 0 10px 0;

      .info {
        &:hover {
          color: var(--color-green-dark);
          cursor: pointer;
        }
      }

      input {
        margin: 5px 10px 0 0;
      }
    }

    .formBtn {
      font-weight: var(--fw-600);
      padding: 10px 30px;
      border: 2px solid var(--color-dark);
      background-color: var(--color-dark);
      color: var(--color-light);
      cursor: pointer;
      text-transform: uppercase;
      letter-spacing: 1px;
      transition: var(--transition);
      margin-top: 30px;
      width: 100%;

      &:hover {
        background-color: var(--color-green-dark);
        border-color: var(--color-green-dark);
      }
    }
  }
}

.customImput,
.react-international-phone-input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;

  border: 1px solid var(--color-light);
  border-radius: 5px;
  font-size: 16px;
}

.phoneSelect {
  border-radius: 5px;
  border: 1px solid var(--color-light);
  margin: 5px 0;
  background-color: var(--color-light);
  padding: 10px 0 10px 10px;

  input {
    width: 100%;
    appearance: none;
    border: none;
  }
}

.contactForm form {
  padding: 20px;
  border-radius: 10px;
  background-color: #f5f5f5;
  box-shadow: var(--shadow);

  textarea {
    min-height: 200px;
  }
}

.errorText {
  color: var(--color-danger);
  display: inline-block;
  width: 100%;
  text-align: left;
}

.note {
  margin: 20px 0;
  width: 100%;
}
