@import "../../../styles/mixins";

.notfoundPage {
  @include flex(column, center, center);
  overflow: hidden;

  height: 100vh;

  a {
    width: 200px;
  }

  .errorWrap {
    padding: 2rem;
  }

  .resInfo {
    font-size: var(--fs-2);
  }
}

.navColors {
  background-color: var(--color-green-dark);
  width: 100vw;
  height: 110px;
  position: absolute;
  top: 0;
}

.logo {
  @include flex(row, center, center);
  padding: 1rem 0;
  cursor: pointer;

  @include sm-max {
    padding: 0;
  }

  img {
    @include img;
  }
}
