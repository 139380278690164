@import "../../../../styles//mixins.scss";

.transparent {
  @include btn;
  background-color: transparent;
  border: 2px solid var(--color-light);
  color: var(--color-light);

  &:hover {
    color: var(--color-light);
    border: 2px solid transparent;
  }
}
