@import "../../../styles/mixins";
@import "../../../styles/typography.scss";

.certificateDetails {
  margin-top: 20px;
  padding: 20px;
}

.componentWrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: space-between;
  gap: 20px;
  margin-top: 70px;

  position: relative;
  animation: fadeIn 0.75s cubic-bezier(0.71, 0.01, 0.24, 0.99);
  transition-delay: var(--deley);

  @include lg-max {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  @include md-max {
    grid-template-columns: repeat(1, 1fr);
  }

  li {
    @include flex(row, center, flex-start);
    background-color: var(--color-light);
    border-radius: 2px;
    box-shadow: var(--shadow);
    border: 1px solid #ccc;
    padding: 0 20px;

    gap: 10px;
    min-height: 120px;
    text-align: left;

    cursor: pointer;
    transition: var(--transition);

    &:hover {
      background-color: #f7f4f4;
    }

    @include md-max {
      padding: 0 10px 10px 10px;
    }

    .image {
      height: 50px;
      width: auto;
      padding: 5px;
    }

    a {
      font-weight: bold;
      transition: var(--transition);
      color: var(--color-dark);
      height: 100%;
      @include flex(row, center, centr);

      &:hover {
        color: var(--color-green-dark);
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
