@import "../../../styles/mixins";
@import "../../../styles/variable.scss";
@import "../../../styles/typography";

.headerBackground {
  @include splash;
  height: auto;
  width: 100%;
  @include flex(column, center, center);
}
