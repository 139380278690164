@import "../../../styles/mixins";

.contentWrap {
  @include flex(row, center, space-between);
  gap: 30px;

  @include md-max {
    flex-direction: column;
  }

  .imgWrap {
    @include flex(row, center, flex-start);
    width: 46%;

    @include md-max {
      justify-content: center;
      width: 100%;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .textWrap {
    width: 48%;

    @include md-max {
      width: 100%;
    }

    h2 {
      text-align: left;
    }
    p {
      margin-top: 20px;
      text-align: justify;
    }

    ul {
      @include flex(column, flex-start, flex-start);
      gap: 30px;
      padding: 30px 0;
      font-weight: bold;

      li {
        border-left: 5px solid var(--color-main);
        padding-left: 20px;
      }
    }
  }
}
