@import "./../../../../styles/typography";

.title {
  font-family: var(--header-font);

  .sectionTitle {
    font-size: var(--fs-section-subtitle);
    color: var(--color-main);
    line-height: 29px;
    font-weight: var(--fw-900);
  }

  .quoteTitle {
    font-size: var(--font-d-section-header);
    line-height: 40px;
    font-weight: var(--fw-900);
    padding-top: 20px;
    width: 100%;

    &::after {
      content: "";
      display: block;
      height: 3px;
      width: 77px;
      background-color: var(--color-main);
      margin-top: 30px;
    }
  }
}
