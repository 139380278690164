@import "../../../../styles/mixins.scss";

.greenTransparentBckg {
  @include btn;
  background-color: transparent;
  border: none;
  color: var(--color-main);
  font-size: 14px;
  line-height: 22px;
  font-weight: var(--fw-900);
  padding: 0;

  &:hover {
    color: var(--color-green-dark);
    background-color: transparent;
  }
}
