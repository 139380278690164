@import "../../../../styles/mixins";

.titlewrap {
  @include flex(row, flex-end, flex-end);
  break-inside: avoid-column;
  margin-bottom: 20px;
  gap: 20px;

  width: 100%;

  @include sm-max {
    flex-direction: column;
    align-items: center;
  }

  @include xl-max {
    max-width: 100%;
    min-width: 100%;
  }

  @include md-max {
    justify-content: center;
  }

  .imgWrapTitle {
    width: 100px;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;

      @media screen and (min-width: 766px) and (max-width: 940px) {
        width: 100px;
        height: 100px;
      }

      @include sm-max {
        margin-right: 0;
      }
    }
  }
}

.header {
  margin-top: 30px;
  font-size: 16px;
  text-transform: uppercase;
  width: 100%;

  @include sm-max {
    text-align: center;
    margin-top: 0;
  }
}
