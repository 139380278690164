@import "../../../../styles/mixins";

.scrollButtons {
  position: fixed;
  right: 30px;
  bottom: 20px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 999;

  @include lg-max {
    display: none;
  }
}

.scrollButtons div {
  width: 40px;
  height: 40px;
  color: #ccc;
  transition: var(--transition);
  cursor: pointer;

  border: 1px solid #ccc;

  @include flex(row, center, center);
  font-size: 1rem;

  backdrop-filter: blur(15px);
  background-color: rgba($color: #fbfbfb, $alpha: 0.2);
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

.next {
  transform: rotate(90deg);
}

.prew {
  transform: rotate(-90deg);
}
