@import "../../../styles/mixins";
@import "../../../styles/variable";

.dropList {
  position: absolute;
  top: 100%;
  width: 70vw;
  margin: 0 auto;
  transition: var(--transition);
  left: 0;
  right: 0;
  z-index: -999;
  @include flex(column, stretch, flex-start);
  background-color: var(--color-dark);
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 1200px) {
    width: 92%;
  }
}

.topNavlink,
.expandedLink,
.activBtn {
  cursor: pointer;
  color: var(--color-light);
  word-wrap: break-word;
}

.expandedLink {
  min-width: 30%;
  width: 30%;
  background-color: var(--color-dark);
  transition: var(--transition);
  padding: 20px;
  color: var(--color-light);
  cursor: pointer;

  &:hover {
    background-color: var(--color-main);
    color: var(--color-light);
  }
}

.topNavlink:hover {
  background-color: var(--color-main);
  transition: var(--transition);
}

.topNavlink {
  padding: 20px;
}

.selectedItem {
  background-color: var(--color-grey-dark);
  transition: var(--transition);
}

.dontSelectedItem {
  width: 100%;
  height: 100%;
}

.moveLeft {
  transition: var(--transition);
}
