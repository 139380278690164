@mixin btn {
  padding: 10px 30px;

  font-family: var(--text-font);
  font-size: var(--font-d-btn);
  font-weight: var(--fw-900);
  text-transform: uppercase;
  transition: var(--transition);
  cursor: pointer;
}

@mixin flex($flex-direction, $align-items, $justify-content) {
  display: flex;
  flex-direction: $flex-direction;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@mixin splash {
  background-size: cover;
  background-attachment: fixed;
  z-index: 9;
  position: relative;
  background-position: center center;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
  }
}

// Mobile devices
@mixin sm-max {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin sm-min {
  @media screen and (min-width: 480px) {
    @content;
  }
}

// Tablet devices
@mixin md-max {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin md-min {
  @media screen and (min-width: 768px) {
    @content;
  }
}

// Desctop devices
@mixin lg-max {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin lg-min {
  @media screen and (min-width: 992px) {
    @content;
  }
}

// Large-desctop devices
@mixin xl-max {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin xl-min {
  @media screen and (min-width: 1200px) {
    @content;
  }
}
