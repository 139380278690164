@import "../../../../styles/mixins";
@import "../../../../styles/typography";

.headline {
  @extend .h3;
  color: var(--color-main);
  font-weight: var(--fw-900);
  font-size: var(--font-d-section-header);
  line-height: 40px;
  margin-top: 20px;

  &::after {
    content: "";
    display: block;
    height: 3px;
    width: 77px;
    background-color: var(--color-main);
    margin-top: 30px;
  }
}
