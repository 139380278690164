@import "../../../styles/mixins";
@import "../../../styles/variable";

.wrapper {
  @include flex(row, center, flex-end);
  width: 100%;
  min-height: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -99;
}

.blackBackground {
  background-color: var(--color-dark);
  position: absolute;
  left: 0;
  width: 30%;
  height: 100%;
  z-index: -999;
}
.sideOpenMenu {
  min-height: 350px;
  padding: 20px 30px;
  color: var(--color-light);
  width: 70%;
  transition: var(--transition);
  background-color: var(--color-grey-dark);
  @include flex(column, stretch, space-between);
  overflow-wrap: scroll;

  .abstractWrap {
    @include flex(row-reverse, flex-start, space-between);

    img {
      width: 18px;
      height: 18px;
      margin-left: 10px;
      cursor: pointer;
    }

    .imgAbstract {
      margin-right: 10px;
      @include flex(row, flex-start, space-between);

      img {
        width: 170px;
        height: 170px;
        margin-right: 20px;
      }
    }
  }

  .headerName {
    font-size: var(--font-d-btn);
    padding: 0 0 16px 0;
    border-bottom: 1px solid var(--color-main);
    text-align: left;
    margin-bottom: 30px;
  }

  .btnWrap {
    @include flex(column, stretch, flex-start);
    margin-top: 30px;
  }

  .megaWrap {
    @include flex(row, stretch, space-between);
    gap: 20px;
    flex-wrap: wrap;
    text-align: left;

    .megaCartWrap {
      flex-grow: 1;
      width: 250px;

      .mainTitle {
        font-weight: bold;
      }

      ul {
        display: flex;
        flex-direction: column;

        .listItems {
          font-weight: lighter;
          text-transform: none;
        }
      }
    }
  }
}
