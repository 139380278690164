@import "../../../styles/mixins";
@import "../../../styles/variable";

.navigationList {
  height: auto;
}
.dropList {
  width: 90vw;
  margin: 0 auto;
  transition: var(--transition);
}

.topNavlink,
.expandedLink,
.activBtn {
  cursor: pointer;
  color: var(--color-light);
  word-wrap: break-word;
  text-transform: uppercase;
}

.expandedLink {
  width: 100%;
  background-color: var(--color-grey-dark);
  transition: var(--transition);
  padding: 10px 0;
  color: var(--color-light);
  padding: 10px 0;

  &:hover {
    color: var(--color-main);
  }
}

.topNavlink:hover {
  background-color: var(--color-main);
}

.topNavlink {
  padding: 20px 10px;
  width: 95vw;

  span {
    display: inline-block;

    font-size: 22px;
    margin-left: 20px;
    transition: transform 0.75s cubic-bezier(0.71, 0.01, 0.24, 0.99);
  }
}

.selectedItem {
  background-color: lighten($color: #1d1d1d, $amount: 5);
}

.dontSelectedItem {
  width: 100%;
  height: 100%;
}

.moveLeft {
  transition: var(--transition);
}
