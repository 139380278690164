@import "../../../../styles/mixins";

.cartWrap {
  @include flex(column, space-around, space-around);

  @include xl-max {
    // max-width: 100%;
    // min-width: 100%;
  }

  @include md-max {
    // padding: 20px 10px;
    // justify-content: center;
  }

  .logos {
    // height: 100%;
    // width: 100%;
    @include flex(row, flex-end, space-between);

    @include sm-max {
      flex-direction: column;
      align-items: center;
    }

    img {
      object-fit: contain;
      width: 100px;
      height: 100%;
      margin-right: 30px;

      @media screen and (min-width: 766px) and (max-width: 940px) {
        width: 100px;
        height: 100px;
      }

      @include sm-max {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }

  .header {
    font-size: 16px;
    text-transform: uppercase;
    width: 100%;

    @include sm-max {
      text-align: center;
    }
  }

  .text {
    margin: 20px 0 0 0;
    line-height: 26px;
  }
}
