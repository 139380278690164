@import "../../../styles/mixins";
@import "../../../styles/typography.scss";

.bannerContainer {
  min-height: 100vh;
  padding-top: 70px;

  background: var(--background-radial);
  @include flex(column, center, center);
  overflow: hidden;

  @include lg-max {
    padding: 120px 0 90px 0;
  }

  @include sm-max {
    min-height: 100vh;
    justify-content: space-around;

    .container {
      height: 100%;
    }
  }
}

.certifications {
  background-color: #f5f5f5;
  padding: 120px 0 90px 0;
  min-height: 100vh;
}

.manuals {
  padding: 120px 0 90px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: justify;
  gap: 50px;

  @include lg-max {
    display: flex;
  }
}

.cartContainer {
  @include flex(row, stretch, space-between);
  padding: 0px 0 90px 0;
  gap: 20px;
  flex-wrap: wrap;

  @include lg-max {
    justify-content: center;
  }

  @include md-max {
    gap: 50px;
  }

  .card {
    padding: 20px 0px 40px 0px;
    border: 1px solid #ccc;
    box-shadow: var(--shadow);
    text-align: justify;
    @include flex(column, flex-start, space-between);
    flex-grow: 1;
    width: calc(33.33% - 20px);

    @include lg-max {
      width: calc(33.33%);
    }

    @include md-max {
      width: calc(50% - 20px);
    }

    @include sm-max {
      padding: 30px 10px 40px 10px;
    }

    div {
      width: 100%;
    }

    .titleWrap {
      min-height: 100px;
      @include flex(row, flex-end, space-between);
      padding: 0 30px;
    }

    .text {
      padding: 40px 30px 40px 30px;
      min-height: 250px;
    }

    ul {
      @include flex(column, flex-start, center);
      width: 100%;

      li {
        @include flex(row, center, flex-start);
        width: 100%;
        padding: 15px 20px;
        gap: 10px;
        text-align: left;

        cursor: pointer;
        transition: var(--transition);

        &:hover {
          background-color: #f7f4f4;
        }

        @include md-max {
          padding: 10px 10px 10px 10px;
        }

        .image {
          height: 50px;
          width: auto;
          padding: 5px;
        }

        a {
          font-weight: bold;
          transition: var(--transition);
          color: var(--color-dark);

          &:hover {
            color: var(--color-green-dark);
          }
        }
      }
    }
  }
}

.textWrap {
  h2,
  h3 {
    text-align: left;
    text-transform: uppercase;
  }

  p {
    text-align: justify;
    padding: 30px 0 60px 0;
    width: 70%;

    @include lg-max {
      width: 100%;
    }
  }
}

.certificationsCartWrap {
  @include flex(row, stretch, space-between);
  gap: 20px;
  flex-wrap: wrap;

  @include md-max {
    gap: 10px;
    justify-content: center;
  }

  .toggleBtn,
  .active {
    @include btn;
    background-color: var(--color-dark);
    border: 2px solid var(--color-dark);
    color: var(--color-light);
    padding: 10px 20px;
    font-weight: var(--fw-900);
    transition: var(--transition);
    cursor: pointer;
    flex-grow: 1;
    height: 80px;

    @include md-max {
      width: 260px;
    }

    @include sm-max {
      width: 100%;
    }

    &:hover {
      background-color: var(--color-green-dark);
      border: 2px solid var(--color-green-dark);
    }
  }

  .active {
    background-color: var(--color-green-dark);
    border: 2px solid var(--color-green-dark);
  }
}
